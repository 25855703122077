.elements-page{
    .elements-banner-section {
        position: relative;
        margin: 0 auto;
        text-align: center;
        margin-top: -6rem;
        z-index: -1;
        @media screen and (max-width: 768px) {
            margin-top: 0rem;
         }
        img {
            width: 100%;
            vertical-align: middle;
            filter: brightness(0.9);
            // height: 660px;
            // @media screen and (max-width: 768px) {
            //     height:auto;
            // }
        }
              
      .centered {
        position: absolute;
        top: 0;
        height:100%;
        // background: rgba(133, 238, 247, 0.5);
        color: #f1f1f1;
        width: 100%;
        padding: 20px;
        background-image:  linear-gradient(45deg,
        rgba(133, 238, 247, 0.9),
        rgba(0, 0, 0, 0.6)), url(
'../../assets/images/elements.svg');
        // background-image: url('../../assets/images/elements.svg');
        background-repeat: repeat-y;
        background-position-x: center;
        background-position-y: center;
        background-repeat: space;
        background-repeat-x: no-repeat;
        // background-blend-mode: exclusion;
        // filter: brightness(0.4);
        @media screen and (max-width: 768px) {
            background-image: none;
        }
        }
        h1{
            font-family: 'Berlin Sans FB';
            position: relative;
            top: 49.6%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 184px;
            font-weight: 900;
        
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        // font-size: 185px;
        // font-weight: 700;
        @media screen and (max-width: 768px) {
            // top: 70%;
            font-size: 36px;
            font-weight: 600;
        }
      }
      }
    
      .typo-description {
        padding: 90px 0px;
        width: 80%;
        margin: auto;
        @media screen and (max-width: 768px) {
            padding: 20px 0px;
            width: 90%;
        }
        .description-top{
            font-weight: 700;
            font-size: 18px;
            line-height: 160.11%;
        }
        .description-bottom {
            margin-top: 50px;
            font-size: 16px;
            line-height: 160.11%;
        }
      }

      .images-section {
        padding: 60px 0px;
        @media screen and (max-width: 768px) {
            padding: 20px 0px;
        }
        .bg-image {
            position: relative;
            z-index: 0;
            img {
                display: block;
            }
            .image-position1{
                position: absolute;
                top: 22%;
                left: 54%;
                width: 30%;
                height: auto;
                border-radius: 53px;
                // border: 3px solid white;
            }
            .image-position2{
                position: absolute;
                top: 22%;
                left: 15%;
                width: 30%;
                height: auto;
                border-radius: 53px;
                // border: 3px solid white;
            }
            .image-position3{
                position: absolute;
                top: 53%;
                left: 36%;
                width: 30%;
                height: auto;
                border-radius: 53px;
                // border: 3px solid white;
            }
        }
      }
      .illustration-section {
        padding: 60px 0px;
        @media screen and (max-width: 768px) {
           padding: 20px 0px;
        }
      }
      .icons-section {
        padding: 90px 0px;
        @media screen and (max-width: 768px) {
           padding: 20px 0px;
        }
        .icons {
            width: 65%;
            margin: auto;
        text-align: center;
        }
      }
    }
    .width{
        width: 88%;
        // margin: auto;

        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }