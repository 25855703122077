.brand-manual{
    .brandmanual-banner-section {
        position: relative;
        margin: 0 auto;
        text-align: center;
        margin-top: -6rem;
        z-index: -1;
        @media screen and (max-width: 768px) {
            margin-top: 0rem;
         }
        img {
            width: 100%;
            vertical-align: middle;
            filter: brightness(0.9);
            // height: 660px;
            // @media screen and (max-width: 768px) {
            //     height:auto;
            // }
        }
              
      .centered {
        position: absolute;
        top: 0;
        height:100%;
        // background: rgba(133, 238, 247, 0.5);
        color: #f1f1f1;
        width: 100%;
        padding: 20px;
        background-image:  linear-gradient(45deg,
        rgba(133, 238, 247, 0.4),
        rgba(0, 0, 0, 0.6)), url(
'../../assets/images/brand-manual-bg.svg');
        // background-image: url('../../assets/images/elements.svg');
        background-repeat: repeat-y;
        background-position-x: center;
        background-position-y: center;
        background-repeat: space;
        // background-blend-mode: exclusion;
        // filter: brightness(0.4);
        @media screen and (max-width: 998px) {
            background-image: none;
        }
       
        }
        h1{
            font-family: 'Berlin Sans FB';
            position: relative;
            top: 49.6%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 184px;
            font-weight: 900;
        
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        // font-size: 185px;
        // font-weight: 700;
        @media screen and (max-width: 768px) {
            // top: 70%;
            font-size: 36px;
            font-weight: 600;
        }
        @media (min-width: 768px) and (max-width: 998px) {
            font-size: 105px;
         }
      }
      }
    
      .casestudy-description {
        padding: 90px 0px;
        width: 80%;
        margin: auto;
        @media screen and (max-width: 768px) {
            padding: 20px 0px;
            width: 90%;
        }
        .description-top{
            font-weight: 700;
            font-size: 18px;
            line-height: 160.11%;
        }
        .description-bottom {
            margin-top: 50px;
            font-size: 16px;
            line-height: 160.11%;
        }
      }

      .classification-cards {
        padding: 60px 0px;
        @media screen and (max-width: 1024px) {
            padding: 40px 0px;
         }
         .cards{
            width: 90%;
            margin: auto;
         }
        .brand-color-cards {
            background: #FFFFFF;
            box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
            border-radius: 51px;
            padding: 0px 10px;
            margin: 20px 20px;
            // z-index: -1;
            @media screen and (max-width: 1024px) {
               margin: 20px 0px;
            }
            .card-img {
                width: 100%;
                // height: 292px;
                // object-fit: contain;
            }
            .card_body {
                padding: 30px;
                @media screen and (max-width: 1024px) {
                    padding: 30px 10px;
                 }

                 .card-text {
                    @media screen and (max-width: 768px) {
                        font-size: 14px;
                     }
                    
                 }
            }
            .card-title {
                margin-bottom: 20px;
                color: #3A3A3A;
                font-size: 30px;
                font-weight: 700;
                font-family: 'Century Gothic Bold';
                @media screen and (max-width: 1024px) {
                    font-size: 24px;
                 }
            }
        }
.readmore {
    text-decoration: none;
    color: #3A3A3A;
    font-family: 'Century Gothic Bold';
    font-size: 16px;
    font-weight: 700;
}

      }
    }