.extendime-page{
    background: #D1FBFF;
    padding: 90px 0px;
    margin-top: -6rem;
    @media screen and (max-width: 768px) {
        margin-top: 0rem;
     }
    .banner{
        .banner-img {
            width: 100%;
        }
    }
    .detail-about {
        .detail-about-flex{
            display: flex;
            flex-direction: row;
            gap: 40px;
            margin-top: 20px;
            @media screen and (max-width: 768px) {
                flex-direction: column;
            }
            .text {
                font-weight: 700;
            }
        }
    }
    .detail-process{
        h3{
            font-size: 32px;
            font-weight: 700;
        }
    }
    .logo-design{
        position: relative;
      
    }

    .logo-design-imgs {
        position: relative;
        background: #FFFFFF;
        padding: 30px;
        @media screen and (max-width: 768px) {
            padding: 3px;
            
        }
        .logo-img-div{
            text-align: center;
            box-shadow: 4px 7px 14px rgba(0, 0, 0, 0.12);
            // padding: 50px;
            img{
                width: 100%;
            }

            @media screen and (max-width: 768px) {
                box-shadow: unset;
                padding: 3px;
            }
        }
    }
.wireframes{
    position: relative;
    .wireframe-imgs {
        position: relative;
        background: #FFFFFF;
        padding: 30px;
        @media screen and (max-width: 768px) {
            padding: 30px 10px;
        }
        img {
            // box-shadow: 4px 7px 14px rgba(0, 0, 0, 0.12);
            width: 100%;
           
        }
    }
}
  

    .typography{
        position: relative;
        .typography-imgs {
            position: relative;
            // background: #FFFFFF;
            // padding: 30px 200px;
            @media screen and (max-width: 768px) {
                // padding: 30px 10px;
            }
        }
    }

    .color-pallette{
        position: relative;
        .color-pallette-imgs{
            position: relative;
            background: #FFFFFF;
            padding: 30px 200px;
            img{
                width: 100%;
            }
            @media screen and (max-width: 768px) {
                padding: 30px 10px;
            }
        }
    }

    .icons {
        position: relative;
        .icons-imgs {
            position: relative;
            // background: #FFFFFF;
            img{
                width: 100%;
            }
            // padding: 30px 200px;
        }
    }

    .ui-elements {
        position: relative;
        .ui-elements-imgs{
            position: relative;
            // background: #FFFFFF;
            // padding: 30px 0px;
            .applynow-img {
                width: auto;
                // @media screen and (max-width: 768px) {
                //    width: 250px;
                // }
            }
            // img{}
        }
    }

    .responsive-design {
        position: relative;
        .responsive-design-imgs {
            position: relative;
            background: #FFFFFF;
            padding: 30px;
        @media screen and (max-width: 768px) {
            padding: 3px;
            
        }
        .responsive-img-div{
            text-align: center;
            // box-shadow: 4px 7px 14px rgba(0, 0, 0, 0.12);
            // padding: 50px;
            img{
                width: 100%;
            }

            @media screen and (max-width: 768px) {
                box-shadow: unset;
                padding: 3px;
            }
        }
    }
}
// .bg-img {
//     background-image: url("../../assets/images/bg-circle.png");
//     background-size: 400px;
//     background-repeat: no-repeat;
//     background-position: top;
//     background-position-x: right;
// }
}
.tester:nth-of-type(odd) {
   padding: 0 115px 0 0;
   @media screen and (max-width: 768px) {
    padding: 0 24px 0 24px;
}
    }


    .circle-bg {
        width: 585.37px;
        position: absolute;
        z-index: 0;
        right: 0px;
        top: 100px;
        transform: translateX(-33%);
        @media screen and (max-width: 768px) {
            width: 130px;
            position: absolute;
            z-index: 0;
            right: -31px;
            top: 51px;
            transform: translateX(-33%);
        }
      }