.contact-page {
    background: #003BC0;
    padding: 90px 0px;
    margin-top: -6rem;
    color: #85EEF7;
.vector-img{
    img{
        position: absolute;
        top: 0;
        right: 0;
        width: auto;
        // z-index: ;
        // height: 279px;
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
}
    .contact-form {
        margin: 130px 0px;
        @media screen and (max-width: 768px) {
            margin: 50px 0px;
        }
        .contact-title {
            font-weight: 700;
            font-size: 60px;
            line-height: 74px;
            @media screen and (max-width: 768px) {
                font-size: 50px;
            }
        }

        .contact-form-control {
            background-color: transparent;
            border: unset;
            color: #85EEF7;
            border-bottom: 1px solid #85EEF7;
            border-radius: 0;
            width: 60%;
            margin: 8px 0px;
            padding: 25px 0px;
            font-size: 20px;
            &:focus{
                box-shadow: unset !important;
                outline: 0;
                background: transparent;
            }
            &:active{
                background: transparent;
            }
            &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: #85EEF7;
                opacity: 1; /* Firefox */
                font-size: 20px;
              }
               @media screen and (max-width: 768px) {
                width: 100%;
            }
        }

        .btns {
            .book-btn{
                border: 1px solid #85EEF7;
                background: transparent;
                font-weight: 700;
                font-size: 20px;
                line-height: 25px;
                color: #85EEF7;
                padding: 12px 26px;
                border-radius: unset;
                &:hover{
                    background-color: #fff;
                    border: 1px solid #fff;
                    color: #003BC0;
                }
            }
        }
    }

    .locations {
        padding: 60px 0px;
        .location-address{
            display: flex;
            gap: 20px;
            margin: 20px 0px;
            .country-name {
                margin-bottom: 33px;
                font-weight: 700;
                font-size: 40px;
                line-height: 49px;
                text-transform: uppercase;
            }
        .address-img{
            img{
                width: auto;
                height: auto;
            }
           
        }
        span{
            margin: 30px 0px;
        }
        }
        .clearfix {
            .pull-left {
                float: left;
            }
            p {
                padding-left: 40px;
                margin-bottom: 2rem;
            }
        }
    }

  
}
.form-control:-webkit-autofill,
.form-control:-webkit-autofill:hover,
.form-control:-webkit-autofill:focus,
.form-control:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.form-control.is-invalid, .was-validated .form-control:invalid{
    background-image: unset !important;
    border-color: unset !important;
}

.form-control.is-valid, .was-validated .form-control:valid{
    background-image: unset !important;
    border-color: unset !important;
}

// input[type=submit]:active {
//     background: transparent !important;
//   }

