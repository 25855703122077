.home {
    background: #D1FBFF;
    padding: 90px 0px;
    margin-top: -6rem;
    .home-banner {
        // position: absolute;
        top: 0;
        .arrow-img {
            width: 30px;
            height: 30px;
            // border-radius: 50%;
            // background-color: #FF5722;
            animation: bounce 2s;
            animation-direction: alternate;
            // animation-timing-function: cubic-bezier(.5, 0.05, 1, .5);
            animation-iteration-count: infinite;
        }
          
        @keyframes bounce {
            from {
                transform: translate3d(0, 0, 0);
            }
            to {
                transform: translate3d(0, 50px, 0);
            }
        }
        .banner-title {
         display: flex;
         gap: 6px;
         margin-bottom: 0px;
         font-weight: 700;
          .banner-title1 {
            font-size: 30px;
            line-height: 40%;
            // font-weight: bold;
            @media (min-width:990px) and (max-width: 1199.98px) {
                font-size: 28px;
             }
             @media (min-width:768) and (max-width: 990px) {
                font-size: 12px;
             }
          }
          .banner-title2 {
            font-size: 60px;
            // font-weight: bold;
          }
        }
    }
    .about-home {
        padding: 90px 0px;
        // margin-top: 100px;
        .about-text-bold {
            font-weight: 700;
            line-height: 167.11%;
            font-size: 19px;
        }
        .about-text {
            line-height: 167.11%;
            line-height: 167.11%;
            font-size: 19px;
        }
    }
    .branding-design{
        .branding-img{
            .branding-image{
                width: 100%;
                height: 600px;
                object-fit: cover;
                position: relative;
                @media screen and (max-width: 768px) {
                    height: auto;
                }
            }
        }
        .brand-row {
            padding: 90px 0px;
            @media screen and (max-width: 768px) {
                padding: 40px 0px;
            }
            .brand-text{
                line-height: 167.11%;
                font-size: 19px;
                @media screen and (max-width: 768px) {
                    font-size: 16px;
                }
            }
  
        }
    }

    .ui-ux-design{
        .ux-img{
            .ux-image{
                width: 100%;
                height: 600px;
                object-fit: cover;
                @media screen and (max-width: 768px) {
                    height: auto;
                }
            }
        }
        .ux-row {
            padding: 90px 0px;
            @media screen and (max-width: 768px) {
                padding: 40px 0px;
            }
            .ux-text{
                line-height: 167.11%;
                font-size: 19px;
                @media screen and (max-width: 768px) {
                    font-size: 16px;
                }
                
            }
        
        }
    }

    .works-section {
        padding: 90px 0px;
        @media screen and (max-width: 768px) {
            padding: 15px 0px 64px 0px;
        }
        .image {
            position: relative;
            width: 100%;
            height: 100%;
          }
          
          .work-img {
            display: block;
            width: 100%;
            height: 100%;
          }
          
          .overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
            transition: .5s ease;
            background: rgba(0, 59, 192, 0.65);
          }
          
          .image:hover .overlay {
            opacity: 1;
          }
          
          .text {
            color: white;
            font-size: 32px;
            position: absolute;
            font-style: italic;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            text-align: center;
          }
        // .work-img {
        //     height: 100%;
        // }
    }
.view-btn {
    color: #000;
    text-decoration: none;
    font-size: 20px;
}
    .find-out-more{
        text-align: center;
        .find-out-title{
            font-size: 32px;
            font-weight: 700;
            @media screen and (max-width: 768px) {
                font-size: 23px;
            }
        }
        .touch-btn {
            .btn {
                font-size: 16px;
                background: #003BC0;
                color: #fff;
                border: 0;
                padding: 15px 31px;
                border-radius: unset;
                &:hover{
                    background: #002B8B;
                }
                // @media screen and (max-width: 768px) {
                //     font-size: 16px;
                // }
            }
        }
    }

}

.loader_wrapper {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    z-index: 9999;
    .loader_img {
      width: 200px;
      height: 150px;
    }
  }

  @media screen and (max-width: 768px) {
    .home{
        .home-banner {
            // position: absolute;
            // top: 220px;
            margin: 35px 0px;
            .banner-title {
                .banner-title1{
                    font-size: 16px;
                }
                .banner-title2{
                    font-size: 30px;
                }
            } 
        }
        .about-home {
            padding: 20px 0px 50px 0px;
            margin-top: unset; 
            .about-text-bold{
                font-size: 16px;
            }
            .about-text {
                font-size: 16px
            }
       }
    }
  }
  @media (min-width: 768px) and (max-width: 1199.98px) { 
    .home{
        // .home-banner {
        //     position: absolute;
        //     top: 50px;
        // }
        .about-home {
            padding: unset; 
            margin-top: unset; 
            position: relative;
       }
    }
  
  }
  .brand-circle1 {
    position: absolute;
    top: 77%;
    left: 0;
    z-index: 0;
    
    @media screen and (max-width: 997px) {
display: none;
    }
    @media (min-width: 998px) and (max-width: 1199.98px) { 
        position: absolute;
        top: 23%;
        left: -130px;
        z-index: 0;
    }
    @media (min-width: 1199.98px) and (max-width: 1400px) { 
        top: 63%;
    }
    @media (min-width: 1400px) and (max-width: 2000px) { 
        top: 88%;
    }

    img {
        width: auto;
        height: 735px;
    }
    @media screen and (max-width: 768px) {
        display: none;
    }
  }

