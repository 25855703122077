.color-page{
    .color-banner-section {
        position: relative;
        margin: 0 auto;
        text-align: center;
        margin-top: -6rem;
        z-index: -1;
        @media screen and (max-width: 768px) {
            margin-top: 0rem;
         }
        img {
            width: 100%;
            vertical-align: middle;
            filter: brightness(0.5);
            height: 660px;
            @media screen and (max-width: 768px) {
                height:auto;
            }
        }
              
      .centered {
        position: absolute;
        top: 0;
        height:100%;
        // background: rgba(133, 238, 247, 0.1);
        color: #f1f1f1;
        width: 100%;
        padding: 20px;
        background-image: linear-gradient(45deg,
        rgba(133, 238, 247, 0.9),
        rgba(0, 0, 0, 0.6)),url('../../assets/images/color-bg.svg');
        background-repeat: repeat-y;
        background-position-x: center;
        background-position-y: center;
        // background-repeat: space;
        // background-repeat-x: no-repeat;
        @media screen and (max-width: 768px) {
            background-image: none;
        }
        }
        h1{
            font-family: 'Berlin Sans FB';
            position: relative;
            top: 49.6%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 184px;
            font-weight: 900;
        
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        // font-size: 185px;
        // font-weight: 700;
        @media screen and (max-width: 768px) {
            // top: 65%;
            font-size: 40px;
            font-weight: 600;
        }
      }
      }
      .color-description {
        padding: 90px 0px;
        width: 70%;
        margin: auto;
        @media screen and (max-width: 768px) {
            padding: 50px 0px;
            width: 90%;
        }
        .description-top{
            font-weight: 700;
            font-size: 18px;
            line-height: 160.11%;
        }
        .description-bottom {
            margin-top: 50px;
            font-size: 16px;
            line-height: 160.11%;
        }
      }

      .color-cards {
        padding: 60px 0px;
        @media screen and (max-width: 1024px) {
            padding: 40px 0px;
         }
         .cards{
            width: 90%;
            margin: auto;
         }
        .brand-color-cards {
            background: #FFFFFF;
            box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
            border-radius: 51px;
            padding: 0px 10px;
            margin: 20px 20px;
            z-index: -1;
            @media screen and (max-width: 1024px) {
               margin: 20px 0px;
            }
            .card-img {
                width: 100%;
                // height: 292px;
                // object-fit: contain;
            }
            .card_body {
                padding: 30px;
                @media screen and (max-width: 1024px) {
                    padding: 30px 10px;
                 }

                 .card-text {
                    @media screen and (max-width: 768px) {
                        font-size: 14px;
                     }
                    
                 }
            }
            .rgb{
                font-weight: 700;
                font-size: 16px;
                line-height: 141.11%;
                color: #3A3A3A;
                margin: 10px 0px;
            }
            .rgba{
                font-weight: 700;
                font-size: 16px;
                line-height: 141.11%;
                color: #3A3A3A;
            }
            .card-title {
                margin: 20px 0px;
                color: #3A3A3A;
                font-size: 30px;
                font-weight: 700;
                @media screen and (max-width: 1024px) {
                    font-size: 24px;
                 }
            }
        }
      }
}

// .stroke {
//     color: transparent;
//   -webkit-text-fill-color: transparent;
//   -webkit-text-stroke-width: 1px;
//   -webkit-text-stroke-color: black;
// }