.logo-page{
    .logo-banner-section {
        position: relative;
        margin: 0 auto;
        text-align: center;
        margin-top: -6rem;
        z-index: -1;
        @media screen and (max-width: 768px) {
            margin-top: 0rem;
        }
        img {
            width: 100%;
            vertical-align: middle;
            filter: brightness(0.5);
            height: 660px;
            @media screen and (max-width: 768px) {
                height:auto;
            }
        }
              
      .centered {
        position: absolute;
        top: 0;
        height:100%;
        // background: rgba(133, 238, 247, 0.4);
        color: #f1f1f1;
        width: 100%;
        padding: 20px;
        background-image:linear-gradient(45deg,
        rgba(133, 238, 247, 0.9),
        rgba(0, 0, 0, 0.6)), url('../../assets/images/Logo.png');
        background-repeat: repeat-y;
        background-position-x: center;
        background-position-y: center;
        // background-repeat: space;
        // background-repeat-x: no-repeat;
        // filter: brightness();
        @media screen and (max-width: 768px) {
            background-image: none;
        }
        }
        h1{
            font-family: 'Berlin Sans FB';
            position: relative;
            top: 48.3%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 185px;
            font-weight: 900;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        // font-size: 185px;
        // font-weight: 700;
        @media screen and (max-width: 768px) {
            // top: 65%;
            font-size: 40px;
            // top: 58%;
            // font-size: 50px;
            font-weight: 600;
        }
      }
      }
      

.word-mark {
    padding: 60px 0px;
    .word-mark-top {
        background: #F5F5F5;
        border: 0.5px solid #E9E9EA;
        border-radius: 0px;
        padding: 60px;
        @media screen and (max-width: 768px) {
            padding: 0;
        }

    }
    .word-mark-bottom{
        .img-bird {
            height: 200px;
            img{
                // padding: 25px;
                margin: auto;
                 width: 100%;
                 height: 100%;
            }
        }
        .bg-logo1 {
            background: #003BC0;
            border-radius: 0px;
            text-align: center;
            padding: 20px;
        }
        .bg-logo2 {
            background: #85EEF7;
            border-radius: 0px;
            text-align: center;
            padding: 20px;
        }
        .bg-logo3 {
            background: #454545;
            border-radius: 0px;
            text-align: center;
            padding: 20px;
        }
    }

    .bird-img1 {
        width: auto;
        height: 191px;
        object-fit: cover;
    }
    .db-logo-img {
        width: 269px;
        height: 135px;
        object-fit: cover;
    }
   
}

.exclusive-zone {
    padding: 60px 0px;
    .zone1-text {
        width: 88%;
    }
    img {
        width: auto;
        height: 135px;
        // object-fit: cover;
    }
}

.logo-size {
    padding: 60px 0px;
    .logo-size-text{
        width: 88%;
    }
    .size {
        color: #003BC0;
        font-style: italic;
font-weight: 400;
font-size: 32px;
line-height: 160%;
    }
    img{
        width: auto;
        height: 306px;
        // object-fit: cover;
    }
}

.logos-brand {
    padding: 60px 0px;
    .bg-dark-logo {
        background: #000000;
        text-align: center;
        padding: 20px;
        // height: 400px;
    }
    .bg-colored-logo{
        text-align: center;
        padding: 20px;
        background: #F5F5F5;
    }
    .bg-white-logo{
        text-align: center;
        padding: 20px;
        /* border: 1px solid #000000; */
        background: #ffffff;
    }
}

.logo-placements {
    padding-bottom: 100px;
}

.description {
    font-size: 16px;
    @media screen and (max-width: 768px) {
        font-size: 16px;
    }
}
}
