.works-page{
    margin-top: -6rem;
    .bg-page-color {
        padding: 160px 0px;
    }
    .typo-description {
        padding: 65px 0px;
        // width: 80%;
        margin: auto;
        @media screen and (max-width: 768px) {
            padding: 20px 0px;
            // width: 90%;
        }
        .description-top{
            font-weight: 700;
            font-size: 18px;
            line-height: 160.11%;
        }
        .description-bottom {
            margin-top: 50px;
            font-size: 16px;
            line-height: 160.11%;
        }
      }
      .work-title {
        color: #003BC0;
  font-size: 50px;
  font-style: italic;
//   margin-bottom: 30px;
  @media screen and (max-width: 768px) {
    font-size: 32px;
}
      }
}

// Tabs Style

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
     background-color: transparent !important;
    border-color: transparent !important;
    color: #003BC0 !important;
    font-size: 32px !important;
    border-bottom: 2px solid #003BC0 !important;
    @media screen and (max-width: 768px) {
        font-size: 20px !important;
    }
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link {
    color:#454545!important;
    font-size: 32px !important;
    @media screen and (max-width: 768px) {
        font-size: 20px !important;
    }
}

.nav-fill .nav-item, .nav-fill>.nav-link {
    flex: none !important;
}

.nav-tabs {
    display: flex;
    justify-content: flex-end;
    border-bottom: 2px solid #fff !important;
}
.nav-tabs .nav-link.active:hover{
    border-bottom: 2px solid #003BC0 !important;

}
  .nav-link:hover {
    isolation: isolate;
    border-color: transparent !important;
}