// .App {
//   text-align: center;
// }

@font-face {
  font-family: 'Century Gothic';
  src: url('./assets/fonts/CenturyGothic.woff2') format('woff2'),
      url('./assets/fonts/CenturyGothic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  // font-display: swap;
}

@font-face {
  font-family: 'Berlin Sans FB';
  src: url('./assets/fonts/BerlinSansFB-Reg.woff2') format('woff2'),
      url('./assets/fonts/BerlinSansFB-Reg.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Century Gothic Bold';
  src: url('./assets/fonts/CenturyGothicPaneuropean-Bold.woff2') format('woff2'),
      url('./assets/fonts/CenturyGothicPaneuropean-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

body{
  font-family: 'Century Gothic'!important;
  overflow-y: scroll;
  overflow-x: hidden;
}
/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #6d6d6d; 
}

.title{
  color: #003BC0;
  font-size: 80px;
  font-style: italic;
  margin-bottom: 30px;
  // font-weight: 700;
    // -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
    // -webkit-text-stroke-width: 1px;
    // -webkit-text-stroke-color: black;

}
.title2{
  color: #003BC0;
  font-size: 50px;
  font-style: italic;
  margin-bottom: 30px;

}

.detail-title{
  color: #003BC0;
  font-size: 62px;
  font-style: italic;
  margin-bottom: 30px;

  @media screen and (max-width: 768px) {
    font-size: 32px;
}
}
.section {
  padding: 160px 0px;
  @media screen and (max-width: 768px) {
    padding: 30px 0px;
}
}
// .bg-page-color{
//   background-color: #D1FBFF;
// }
.cards{
  z-index: 0;
  position: relative;
}
.bg-page-color {
  background-color: #D1FBFF;
}
@media screen and (max-width: 769px) {
  .title{
    font-size: 36px;
    // font-weight: 700;
  }
  .title2{
    font-size: 36px;
  }
}

.scrollbar-width-none{
  scrollbar-width: none !important; 
}

.nodata {
  text-align: center;
  margin:300px 0px;
}
.loader_img {
  width: 200px;
  height: 150px;
}