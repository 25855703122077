.header{
  .navbar-toggler {
    border: 0 !important;
    background: #FFFFFF;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
    border-radius: 45px;
    // height: 50px;
    padding: 16px 20px !important;
    width: auto;
    position: relative;
    z-index: 2;
    &:focus{
        box-shadow:0px 3px 8px rgba(0, 0, 0, 0.1) !important;
        
    }
  }

.main-logo {
    width: 220px;
    height: 50px;
}
 
}
.social-icons {
    display: flex;
    gap: 40px;
    margin-top: 60px;
    .social-icon-link {
       height: 25px;
       width: 25px;
    }
  }
.btn-close{
    background-color: #FFFFFF !important;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1) !important;
    opacity: unset !important;
    border-radius: 2.375rem !important;
    height: 40px !important;
    width: 40px !important;
    background-image: url('../../assets/images/close-icon.svg') !important;
  }
.offcanvas.show:not(.hiding), .offcanvas.showing{
    width: 100% !important;
    height: 100% !important;
}
.offcanvas-backdrop.show{
    opacity: 0 !important;
}
.offcanvas{
    background-color: rgba(0, 59, 192, 0.95) !important;
}
// .offcanvas-header{
//     justify-content: end !important;
// }
.offcanvas-body{
padding: 40px !important;
color: #FFFFFF;
overflow-y: unset !important;
}
.main-nav-link {
    font-size: 64px !important;
    font-weight: 700 !important;
    font-style: italic;
    margin: 20px 0px;
}

.dropdown-menu[data-bs-popper] {
    top: 50% !important;
    left: 0;
    margin-top: var(--bs-dropdown-spacer);
}
.offcanvas.offcanvas-end{
    width: 100% !important;
}
.dropdown-menu.show{
    background: transparent;
    border: 0;
    justify-content: center;
    display: grid !important;
    @media screen and (max-width: 768px) {
        justify-content: end;
    }
    @media (min-width: 768px) and (max-width: 1024px) { 
        justify-content: end;
     }
}
.dropdown-item{
    color: #fff !important;
    font-size: 25px;
    padding: 10px;
}

// .dropdown-toggle::after {
//     display: none !important;
// }
.dropdown-item:focus, .dropdown-item:hover{
    background-color: unset !important;
    color: #000;
}
.offcanvas.show:not(.hiding), .offcanvas.showing {
    transform: none;
    overflow-y: auto;
}
.navbar-nav .dropdown-menu {
    position: absolute !important;
    right: 0;
    @media screen and (max-width: 768px) {
        position: static !important;
    }
}
.navbar-toggler {
    margin-right: 0px !important;
}

.navbar-toggler-icon{
    background-image: url('../../assets/images/toggler.svg') !important;
        width: 19px !important;
    height: 21px !important;
}

@media screen and (max-width: 769px) {
  
    .main-nav-link {
        font-size: 20px !important;
        // padding: 20px 0px !important;
    }
    .dropdown-item{
        color: #fff !important;
        font-size: 12px;
    }
}


.navbar{
    background-color: transparent;
    // transition: all 0.6s ease;
    @media screen and (max-width: 768px) {
        background: rgba(0, 0, 0, 0.35);
     }
  
}
  
.navbar.colorChange{
    background: rgba(0, 0, 0, 0.35);
    position: fixed;
    top: 0;
    width: 100%;
    transition: all 0.6s ease;
    z-index: 1;
}
// .nav-fixed {
//     position: fixed;
//     top: 0;
// }


// .dropbtn {
//     background-color: transparent;
//     color: white;
//     padding: 16px;
//     font-size: 16px;
//     border: none;
//   }
  
//   .dropdown {
//     position: relative;
//     display: inline-block;
//   }
  
//   .dropdown-content {
//     display: none;
//     position: absolute;
//     background-color: transparent;
//     min-width: 160px;
//     opacity: 0;
//          transition: all linear 0.3s;
//     color: #fff;
//     z-index: 1;
//   }
  
//   .dropdown-content a {
//     color: #fff;
//     padding: 12px 16px;
//     text-decoration: none;
//     display: block;
//   }
  
  
//   .dropdown:hover .dropdown-content {
//     display: block;
//     transition: all linear 1s;
//      opacity: 1;
// }
  

// .dropbtn {
//     border-radius: 12px;
//     background-color: transparent;
//     color: white;
//     padding: 16px;
//     border: none;
//     cursor: pointer;
    
//   }
  
  .dropdown {
    border-radius: 12px;
    position: relative;
    font-size: 64px;
    display: inline-block;
    font-weight: 700;
    height: 100px;
    @media screen and (max-width: 768px) {
        height: 50px;
     }
    .nav-link{
        width: fit-content;
        @media screen and (max-width: 768px) {
           padding: 0px;
        }
    }
    @media screen and (max-width: 768px) {
        font-size: 24px;
    }
  }

  .dropdown-content {
    border-radius: 12px;
    opacity: 0;
    // display: none;
    position: absolute;
    background-color: transparent;
    min-width: 160px;
    z-index: 1;
     transition: all linear 0.3s;
     right: 42%;
         top: 59%;
             @media screen and (max-width: 320px) {
        right: -120px;
        top: 27%;
    }
    @media (min-width: 321px) and (max-width: 425px) { 
        right: -26%;
        top: 36%;
     }
     @media (min-width: 425px) and (max-width: 767.99px) { 
        right: 11%;
        top: 35%;
     }
    @media (min-width: 768px) and (max-width: 1024px) { 
        right: -14%;
     }
    @media (min-width: 1024px) and (max-width: 1200px) { 
        right: 17%;
     }
     @media (min-width: 1200px) and (max-width: 1400px) { 
        right: 32%;
     }
  }
  
  .dropdown-content a {
    color: #fff;
    padding: 5px 16px;
    text-decoration: none;
    display: block;
    font-size: 32px;
    font-style: italic;
    font-weight: 400;

    @media screen and (max-width: 768px) {
        font-size: 12px;
        padding: 6px 14px;
    }
  }
  
  .dropdown-content a:hover {
    cursor: pointer;
  }
  
  .dropdown:hover .dropdown-content {
    transition: all linear 1s;
     opacity: 1;
    //  transition: display 2s
    //  display: block;
  }
  
  
  .dropdown:hover .dropbtn {
     transition: all linear 1s;
    cursor: pointer;
   
  }


