.creative {
    margin-top: 90px;
    .card{
        border: none;
        cursor: pointer;
        margin: 10px 0px;

    }
    .card-title{
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: #454545;
        }
        
}