.typography-page{
.typography-banner-section {
    position: relative;
    margin: 0 auto;
    text-align: center;
    margin-top: -6rem;
    z-index: -1;
    @media screen and (max-width: 768px) {
        margin-top: 0rem;
     }
    img {
        width: 100%;
        vertical-align: middle;
        filter: brightness(0.5);
        // height: 660px;
        // @media screen and (max-width: 768px) {
        //     height:auto;
        // }
    }
          
  .centered {
    position: absolute;
    top: 0;
    height:100%;
    // background: rgba(133, 238, 247, 0.5);
    color: #f1f1f1;
    width: 100%;
    padding: 20px;
    background-image:linear-gradient(45deg,
    rgba(133, 238, 247, 0.9),
    rgba(0, 0, 0, 0.6)), url('../../assets/images/typography.svg');
    background-repeat: repeat-y;
    background-position-x: center;
    background-position-y: center;
    // background-repeat: space;
    // filter: brightness(0.4);
    @media screen and (max-width: 768px) {
        background-image: none;
    }
    }
    h1{
        font-family: 'Berlin Sans FB';
        position: relative;
        top: 48%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 184px;
        font-weight: 900;
    
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // font-size: 185px;
    // font-weight: 700;
    @media screen and (max-width: 768px) {
        // top: 70%;
        font-size: 36px;
        font-weight: 600;
    }
  }
  }

  .typo-description {
    padding: 90px 0px;
    width: 80%;
    margin: auto;
    @media screen and (max-width: 768px) {
        padding: 30px 0px;
        width: 90%;
    }
    .description-top{
        font-weight: 700;
        font-size: 18px;
        line-height: 160.11%;
    }
    .description-bottom {
        margin-top: 50px;
        font-size: 16px;
        line-height: 160.11%;
    }
  }

.typo-font{
    padding: 90px 0px;
    @media screen and (max-width: 768px) {
        padding: 30px 0px;
    }
    .typo-family{
        .typo-family-large {
            font-size: 180px;
            line-height: 141.11%;
            font-weight: 700;
            font-family: 'Century Gothic';
            @media (min-width: 1024px) and (max-width: 1199.98px) {
                font-size: 135px;
             }
            @media screen and (max-width: 1024px) {
                font-size: 100px;
                text-align: center;
                // letter-spacing: 0.8em;
              }
        }
        .typo-family-black {
            font-family: 'Century Gothic';
font-style: normal;
font-weight: 700;
font-size: 60px;
color: #454545;
margin-bottom: -1rem;
@media screen and (max-width: 1024px) {
    font-size: 40px;
}
        }
        .typo-family-gray {
            font-family: 'Century Gothic';
            font-style: normal;
            font-weight: 700;
            font-size: 60px;
            color: #898989;
            margin-bottom: -1rem;
            
            margin-left: -1rem;
            @media screen and (max-width: 1024px) {
                font-size: 40px;
                margin-left: unset;
            }

        }
        .typo-family-blue {
            font-family: 'Century Gothic';
            font-style: normal;
            font-weight: 700;
            font-size: 60px;
            color: #003BC0;
            margin-bottom: -1rem;
            @media screen and (max-width: 1024px) {
                font-size: 40px;
            }
            // margin-left: -1rem;

        }
        .typo-family-electricblue {
            font-family: 'Century Gothic';
            font-style: normal;
            font-weight: 700;
            font-size: 60px;
            color: #85EEF7;
            margin-bottom: -1rem;

            margin-left: -1rem;
            @media screen and (max-width: 1024px) {
                font-size: 40px;
                margin-left: unset;
            }
        }
    }

    .font-family-regular{
        .regular {
            font-size: 20px;
        }
        .letters{
            font-size: 20px;
            line-height: 141.11%;
        }
        .numbers{
            font-size: 20px;
          line-height: 141.11%;
         /* or 28px */
          letter-spacing: 1.22em;
          @media screen and (max-width: 768px) {
            font-size: 16px;
            // letter-spacing: 0.8em;
          }
        }
    }

    .font-family-bold{
        margin-top: 50px;
        .regular {
            font-size: 20px;
            font-weight: 700;
        }
        .letters{
            font-size: 20px;
            line-height: 141.11%;
            font-weight: 700;
        }
        .numbers{
            font-size: 20px;
          line-height: 141.11%;
         /* or 28px */
          letter-spacing: 1.22em;
          font-weight: 700;
          @media screen and (max-width: 768px) {
            font-size: 16px;
          }
        }
    }
    @media screen and (max-width: 768px) {
        .offset-2 {
            margin-left: unset !important;
        }
      }
}

}