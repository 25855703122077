.footer {
    // margin-top: 62.2vh;
    background: #454545;
    padding: 30px;
    position: relative;
    .footer-body {
        text-align: center;
        .footer-logo{
            margin-top: 40px;
        }
        .footer-text {
            margin-top: 50px;
            color: #fff;
        }
    }

    .bottom-img {
        position: absolute;
        right: 0;
        /* top: -227px; */
        bottom: 60%;
        img{
            width: 230px;
            height: 230px;
        }
    }
 
}