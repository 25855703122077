// @import "../../styles/Colors.scss";
.loderHOC{
  width: 100%;
  .loader_wrapper {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    z-index: 9999;
    .loader_img {
      width: 200px;
      height: 150px;
    }
  }
}

