.case-studies{
    .casestudies-banner-section {
        position: relative;
        margin: 0 auto;
        text-align: center;
        margin-top: -6rem;
        z-index: -1;
        @media screen and (max-width: 768px) {
            margin-top: 0rem;
         }
        img {
            width: 100%;
            vertical-align: middle;
            filter: brightness(0.9);
            // height: 660px;
            // @media screen and (max-width: 768px) {
            //     height:auto;
            // }
        }
              
      .centered {
        position: absolute;
        top: 0;
        height:100%;
        // background: rgba(133, 238, 247, 0.5);
        color: #f1f1f1;
        width: 100%;
        padding: 20px;
        background-image:  linear-gradient(45deg,
        rgba(133, 238, 247, 0.2),
        rgba(0, 0, 0, 0.6)), url(
'../../assets/images/casestudies-banner.svg');
        // background-image: url('../../assets/images/elements.svg');
        background-repeat: repeat-y;
        background-position-x: center;
        background-position-y: center;
        background-repeat: space;
        // background-blend-mode: exclusion;
        // filter: brightness(0.4);
        @media screen and (max-width: 998px) {
            background-image: none;
        }
       
        }
        h1{
            font-family: 'Berlin Sans FB';
            position: relative;
            top: 49.6%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 184px;
            font-weight: 900;
        
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        // font-size: 185px;
        // font-weight: 700;
        @media screen and (max-width: 768px) {
            // top: 70%;
            font-size: 36px;
            font-weight: 600;
        }
        @media (min-width: 768px) and (max-width: 998px) {
            font-size: 105px;
         }
      }
      }
    
      .casestudy-description {
        padding: 90px 0px;
        width: 80%;
        margin: auto;
        @media screen and (max-width: 768px) {
            padding: 20px 0px;
            width: 90%;
        }
        .description-top{
            font-weight: 700;
            font-size: 18px;
            line-height: 160.11%;
        }
        .description-bottom {
            margin-top: 50px;
            font-size: 16px;
            line-height: 160.11%;
        }
      }

      .extendime-section {
        padding: 90px 0px;
        .extendime-img {
            img{
                height: 400px;
            }
        }
        // .move-down{
        //     padding: 90px 0px;
        // }
        // .move-up {
        //     padding: 90px 0px;
        // }
        @media screen and (max-width: 768px) {
            padding: 40px 0px;
            .move-down {
                order: 2;
                // padding: 30px 0px;
            }
            .move-up {
                order: 1;
                // padding: 30px 0px;
            }
        }

        h3 {
            font-size: 32px;
            font-weight: 700;
        }
        .read-btn {
            margin: 20px 0px;
        }
        .read-more {
            text-decoration: none;
            color: #454545;
            font-size: 16px;
            font-weight: 700;
            // text-transform: uppercase;
            @media screen and (max-width: 768px) {
                margin: 20px 0px;
            }
        }
      }
      .load-more {
        padding: 90px 0px;
        .load-more-btn {
            font-size: 22px;
            color: #454545;
            .arrow-img {
                width: 20px;
                height: 20px;
                // border-radius: 50%;
                // background-color: #FF5722;
                animation: bounce 2s;
                animation-direction: alternate;
                // animation-timing-function: cubic-bezier(.5, 0.05, 1, .5);
                animation-iteration-count: infinite;
            }
              
            @keyframes bounce {
                from {
                    transform: translate3d(0, 0, 0);
                }
                to {
                    transform: translate3d(0, 5px, 0);
                }
            }
        }
      }
    }

    .caseStudies-section {
        padding: 100px 0px;
        @media screen and (max-width: 768px) {
            padding: 30px 0px;
        }
    }