.modal-header {
    padding: 20px 10px 10px 60px !important;
    border-bottom:unset !important;
    @media screen and (max-width: 768px) {
        padding: 20px 10px 10px 10px !important;
    }
    .modal-title {
        font-size: 32px;
        font-weight: 700;
        color: #003BC0;
        @media screen and (max-width: 768px) {
            font-size: 20px;
        }
    }
    .btn-close {
        background-color: transparent !important;
        box-shadow: unset !important; 
        opacity: unset !important;
        // background: transparent url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e) center/1em auto no-repeat;
    }
} 

.carousel {
    width: 500px;
    margin: auto;
    @media screen and (max-width: 991px) {
        width: 100%;
    }

    .carousel-control-next-icon {
        background-image: url(../../../../assets/images/icons/arrow-right.png) !important;
    }
    .carousel-control-prev-icon{
        background-image: url(../../../../assets/images/icons/arrow-left.png) !important;
    }

    .carousel-control-next {
        right: -100px !important;
        @media screen and (max-width: 991px) {
            right: -60px !important;
        }
    }
    .carousel-control-prev {
        left: -100px !important;
        @media screen and (max-width: 991px) {
            left: -60px !important;
        }
    }
    .carousel-control-next, .carousel-control-prev{
        opacity: 1 !important;
    }
}
.carousel-item {
    img{
        height: 300px !important;
    }
}
.carousel-indicators{
    display: none !important;
}
.modal-dialog {
    position: relative;
    width: auto;
    margin: var(--bs-modal-margin);
    pointer-events: none;
    @media screen and (max-width: 768px) {
        margin: unset;
    }
}
.modal-content {
    border: 0 !important;
}
.modal-body {
    padding: 20px 60px 40px 60px !important;
    @media screen and (max-width: 768px) {
        padding: 20px 10px 10px 10px !important;
    }
}